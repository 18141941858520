import request from './request'

// 公司相关 3 老接口


// 预支规则
/**
 * 查询公司预支规则配置及企业
 *
 * */
export const getAdvanceConfig = async (params: any) => {
  return await request.get('/rest/pay/advance/rule/config/get-advance-config', {
    params: params
  })
}
/**
 * 设置公司预支规则
 *
 * */
export const setAdvanceConfig = async (data: any) => {
  return await request.put('/rest/pay/advance/rule/config/set-company-config', data)
}
/**
 * 获取公司全部企业列表
 *
 * */
export const queryServeCompany = async (params: any, config: any) => {
  return await request.get('/rest/company/query-serve-company', {
    params: params,
    headers: config
  })
}
/**
 * 批量配置预支企业
 *
 * */
export const configAdvanceServeCompany = async (data: any) => {
  return await request.post('/rest/advance/serve/company/association/config-advance-serve-company', data)
}
/**
 * 删除预支企业
 *
 * */
export const deleteAdvanceCompany = async (data: any) => {
  return await request.put('/rest/advance/serve/company/association/delete', data)
}
/**
 * 获取企业员工列表
 *
 * */
export const getEmployeeList = async (params: any) => {
  return await request.get('/saas/note/saasCompanyEmployee', {
    params
  })
}
/**
 * 修改企业负责人
 *
 * */
export const editServerCompany = async (data: any) => {
  return await request.put('/admin/company/edit-server-company', data)
}
/**
 * 预支申请单—预支单
 *
 * */
export const queryAdvanceNote = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/query-advance-note', {
    params: params
  })
}
/**
 * 导出申请单—预支单
 *
 * */
export const exportAdvanceNote = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/export-advance-note', {
    params: params
  })
}
/**
 * 查看预支单详情
 *
 * */
export const paymentRecordList = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/list', {
    params: params
  })
}
/**
 * 查询公司下派遣单位列表
 *
 * */
export const getEnterpriseNameList = async (params: any) => {
  return request.get('/rest/pay/serve-company/list-to-pay', {
    headers: params
  })
}
/**
 * 获取预支人员明细
 *
 * */
export const paymentRecordExport = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/export', {
    params: params
  })
}
/**
 * 获取预支人员明细
 *
 * */
export const advanceUserInfo = async (params: any) => {
  return await request.get('/blue/user/info', {
    params: params
  })
}
/**
 * 停止发薪
 *
 * */
export const updateStatus = async (ids: any) => {
  return await request.put('/rest/pay/advance/payment/record/updateStatus/'+ids)
}
/**
 * 获取预支人员明细
 *
 * */
export const queryCheckList = async (params: any) => {
  return await request.get('/rest/pay/company/employee/salary/query-check-list', {
    params: params
  })
}
/**
 * 发薪记录明细(筛选月份内的)
 *
 * */
export const queryPayRecord = async (params: any) => {
  return await request.get('/rest/pay/company/employee/salary/query-pay-record', {
    params: params
  })
}
/**
 * 预支记录明细(筛选月份内的)
 *
 * */
export const queryAdvanceRecord = async (params: any) => {
  return await request.get('/rest/pay/company/employee/salary/query-advance-record', {
    params: params
  })
}
/**
 * 财务对账—对账—头部总和
 *
 * */
export const calculateFinancialSummary = async (params: any) => {
  return await request.get('/pay/processOrder/calculate-financial-summary', {
    params: params
  })
}
/**
 * 预支对账列表
 *
 * */
export const queryAdvanceAccountCheck = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/query-advance-account-check', {
    params: params
  })
}


/**
 * 核算列表详情
 *
 * */
export const queryCheckDetails = async (params: any) => {
  return await request.get('/rest/pay/company/employee/salary/query-check-details', {
    params: params
  })
}
/**
 * 查询预支领薪记录和发薪领薪记录
 *
 * */
export const queryPaymentRecord = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/query-payment-record', {
    params: params
  })
}
/**
 * 导出核算列表
 *
 * */
export const exportCheckList = async (params: any) => {
  return await request.get('/rest/pay/company/employee/salary/export-check-list', {
    params: params
  })
}
/**
 * 导出核算列表详情
 *
 * */
export const exportCheckDetails = async (params: any) => {
  return await request.get('/rest/pay/company/employee/salary/export-check-details', {
    params: params
  })
}
/**
 * 查询员工个人全部预支记录列表
 *
 * */
export const queryUserAdvanceList = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/query-user-advance-list', {
    params: params
  })
}

/**
 * 员工个人预支记录，预支汇总金额
 *
 * */
export const queryUserEmployeePrepaidRecordSum = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/query-user-employee-prepaid-record-sum', {
    params: params
  })
}


/**
 * 预支单发薪单详情
 *
 * */
export const queryAdvanceApplyDetail = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/query-advance-apply-detail', {
    params: params
  })
}

/**
 * 导出预支对账列表
 *
 * */
export const exportAdvanceAccountCheck = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/export-advance-account-check', {
    params: params
  })
}

/**
 * 导出员工个人全部预支记录列表
 *
 * */
export const exportUserAdvanceList = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/export-user-advance-list', {
    params: params
  })
}

// 首页
/**
 * 首页统计预支项目数，预支金额
 *
 * */
export const queryCollectData = async (params: any) => {
  return await request.get('/rest/home/statistics/query-collect-data', {
    params: params
  })
}
/**
 * 首页预支趋势图
 *
 * */
export const queryAdvanceTrend = async (params: any) => {
  return await request.get('/rest/home/statistics/query-advance-trend', {
    params: params
  })
}


/**
 * 生成子弹回单
 *
 * */
export const eleAdvanceApply = async (params: any) => {
  return await request.get('/rest/pay/processOrder/ele-advance-apply', {
    params: params
  })
}
/**
 * 下载子弹回单
 *
 * */
export const eleDownload = async (params: any) => {
  return await request.get('/rest/pay/processOrder/ele-advance-download', {
    params: params
  })
}
/**
 * 生成微信回单
 *
 * */
export const billApplyAdvanceMore = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/bill-apply-advance-more', {
    params: params
  })
}
/**
 * 下载微信回单
 *
 * */
export const billDownloadAdvanceMore = async (params: any) => {
  return await request.get('/rest/pay/advance/payment/record/bill-download-advance-more', {
    params: params
  })
}