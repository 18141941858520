import request from './request'
import {
    QueryPageDto,
    QueryDateSlotDto
} from './dto'
// RechargeRecord
export interface RechargeRecordDto extends QueryPageDto, QueryDateSlotDto{
    exportType?:string
}
export interface ResultRechargeRecordDto {
    amount: number
    successTime: string
    status?:string | undefined
}
// 投保充值记录
export function insureRechargeRecord (params:RechargeRecordDto): Promise<{total: number, list:ResultRechargeRecordDto[]}> {
    return request.get('/rest/insure/insure-info/insure-recharge-record', {
        params
    })
}

// 电子签记录
export function elesignRechargeRecord (params:RechargeRecordDto): Promise<{total: number, list:ResultRechargeRecordDto[]}> {
    return request.get('/rest/elesign/ele-sign-contract-record/elesign-recharge-record', {
        params
    })
}

// 发薪记录 rest/pay/recharge/record/list

export function getPayList (params:RechargeRecordDto): Promise<{total: number, list:ResultRechargeRecordDto[]}> {
    return request.get('/rest/pay/recharge/record/list', {
        params
    })
}

// 获取团队账户信息
export function getRechargeList (params:QueryPageDto): Promise<{totalCount: number, list:ResultRechargeRecordDto[]}> {
    return request.get('/saas/note/saasCompany/recharge-record', {
        params
    })
}

// 获取订单信息
export function getCompanySeatInfo (params: QueryPageDto): Promise<{data:ResultRechargeRecordDto[]}> {
    return request.get('/pay/pay-company-seat/company-seat-info', {
        params
    })
}


// 续费 + 购买席位
export function addRechargeRecord (data: any): Promise<{data:ResultRechargeRecordDto[]}> {
    return request.post('/saas/note/saasCompany/add-recharge-record', data)
}

