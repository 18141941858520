
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus';
import * as echarts from 'echarts'

let myChart = null as any
export default defineComponent({
    props: {
        option: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
        }
    },
    watch: {
        option: {
            handler(newval) {
                console.log(111, newval);
                if (newval) {
                    // if ((this as any).$refs.trend) {
                        // this.$nextTick(() => {
                        //     if(!(this as any).myChart) {
                        //         ;(this as any).myChart = echarts.init((this as any).$refs.trend);
                        //     }
                        //     ;(this as any).myChart.setOption(newval)
                        // })
                        this.$nextTick(() => {
                            myChart.setOption(newval)
                        })
                    // }
                }
            },
            deep: true
        }
    },
    mounted() {
        myChart = echarts.init((this as any).$refs.trend)
    },
    methods: {
        init(option: any) {
            if (!myChart) {
                myChart = echarts.init((this as any).$refs.trend)
            }
            myChart.setOption(option)
        }
    }
})
