import { createStore } from 'vuex'
import { longPollingFile, updateExportStatus } from '@/api/export'
import axios, { AxiosRequestConfig } from 'axios'
import { downLoadFile } from '../utils/util'
export default createStore({
    state: {
        auditStatus: false,
        reqnum: 0,
        isNav: false,
        titles: [],
        nickName: '',
        companyName: '',
        avatar: '',
        tagsaName: '',
        tagsPages: [] as { path: string, title: string, name: string, query?: any }[],
        intervalId: '' as any,
        fileIdS: [] as any,
        fileList: [] as any,
        num: 0 as number,
        fileCloneList: [] as any,
        downloadLink: '' as any,
        fileName: '',
        isDownload: true,
        blobFileName: '',
        bankInfo: {},
        changeRechargeTab: '',
        isOpenRecharge: '',
        openDefaultMenuIndex: 0,
        fullScreenContainer: null
    },
    mutations: {
        setNavStatus(state, status: boolean): void {
            state.isNav = status
        },
        setTitles(state, titles: []): void {
            state.titles = titles
        },
        setUser(state, data) {
            state.nickName = data.nickName
            state.avatar = data.avatar
            state.companyName = data.companyName
            state.auditStatus = data.auditStatus
        },
        setBankInfo(state, data) {
            state.bankInfo = data
        },
        // 标签页添加路由
        addPage(state, page) {
            console.log(page, 'metameta')
            state.tagsPages.push({
                path: page.path,
                title: page.meta.titles[page.meta.titles.length - 1] || '',
                name: page.name,
                query: page.query
            })
            console.log(state.tagsPages, 'tagsPages添加')
        },
        // 删除标签页
        delPage(state, page: { page: string, name: string }) {
            state.tagsPages.forEach((item: { path: string, title: string, name: string }, index: number) => {
                if (item.path === page.page) {
                    state.tagsPages.splice(index, 1)
                }
            })
            state.tagsaName = page.name
            console.log(state.tagsPages, 'tagsPages删除')
        },
        // 轮询检测文件下载进度
        getLongPollingFile(state, data) {
            console.log(data, 'data')
            state.reqnum = 0
            state.fileCloneList.push(data || '')
            state.fileCloneList.filter((item: any) => item !== '')
            state.fileIdS.push(data?.id ?? null)
            state.fileIdS = state.fileIdS.filter((item: any) => item !== undefined)
            // 开始计时
            if (!state.intervalId) {
                console.log('看看现在有id吗', state.intervalId)
                const fn = async function runTimer() {
                    console.log(state.reqnum, 'state.reqnum')
                    if (state.reqnum > 5) {
                        return
                    }
                    state.reqnum += 1
                    const result = await longPollingFile({
                        ids: state.fileIdS.toString(',')
                    }, {
                        loading: true
                    }).catch(() => {
                        if (state.intervalId) {
                            clearInterval(state.intervalId)
                            state.intervalId = ''
                        }
                    })
                    state.fileList = result
                    console.log('进入了看一下id', state.intervalId)
                    console.log('进入了看一下list', state.fileList)
                    if (result.length === 0) {
                        clearInterval(state.intervalId)
                        state.intervalId = ''
                        return
                    }
                    result.forEach((item: { fileUrl: string, id: number, exportType: number, fileName: string, updateTime: any, remark: any }) => {
                        if (item.fileUrl) {
                            axios({
                                url: item.fileUrl,
                                method: 'GET',
                                responseType: 'blob'
                            }).then(async (response) => {
                                downLoadFile(response, item.exportType, item.updateTime, item.remark)
                                state.fileCloneList.filter((ite: any) => ite.id !== item.id)
                                await updateExportStatus({
                                    id: item.id
                                }, {
                                    loading: true
                                })
                                console.log('下载下载', item.id)
                            })
                        }

                        // if (item.exportType === 4 && item.fileUrl) {
                        //     if (item.fileUrl.includes('.xlsx')) {
                        //         axios({
                        //             url: item.fileUrl,
                        //             method: 'GET',
                        //             responseType: 'blob'
                        //         }).then(async (response) => {
                        //             downLoadFile(response)
                        //             state.fileCloneList.filter((ite:any) => ite.id !== item.id)
                        //             updateExportStatus({
                        //                 id: item.id
                        //             }, {
                        //                 loading: true
                        //             })
                        //             console.log('下载下载', item.id)
                        //         })
                        //     } else {
                        //         window.location.href = process.env.VUE_APP_BASE_URL +
                        //         `/common/${'downloadZip'}?delete=false&${'uuid'}=` + item.fileUrl
                        //         state.fileCloneList.filter((ite:any) => ite.id !== item.id)
                        //         updateExportStatus({
                        //             id: item.id
                        //         }, {
                        //             loading: true
                        //         })
                        //         console.log('下载下载', item.id)
                        //     }
                        // } else if (item.fileUrl) {

                        // }
                    })
                }
                fn()
                state.intervalId = setInterval(async () => {
                    fn()
                }, 4000)
            }
        },
        onModifyDownload(state) {
            state.isDownload = !state.isDownload
        },
        stopFileTimer(state) {
            // 停止计时
            clearInterval(state.intervalId)
        },
        ces(state) {
            setInterval(() => {
                state.num += 1
            }, 5000)
        },
        setBlobFileName(state, data) {
            state.blobFileName = data
        },
        changeRechargeTab(state, tab) {
            console.log('store', tab)
            state.changeRechargeTab = tab
        },
        openRecharge(state, data) {
            state.isOpenRecharge = data
        },
        setDefaultOpenMenuIndex(state, data) {
            state.openDefaultMenuIndex = data
        },
        setFullScreenContainer(state, el) {
            console.log(777, el)
            state.fullScreenContainer = el
        }
    },
    actions: {
    }
})
