import request from './request'
import { QueryDateSlotDto, QueryPageDto, QueryMonthDto } from './dto'

export interface ProcessOrderDto {
    userName: string
    idCardNo: string
    id: number
    companyName: string
    createTime: string
    createUserId: number
    createUserName: string
    flowCode: number
    projectName: string
    remarks: string
    serveCompanyId: number
    serveCompanyName: string
    status: string
    peopleNum: number
    totalMoney: number
    isCreateReceipt: number // 回单状态 0 未生成   1 已生成     2 生成中
}
interface GetProcessOrderResDto {
    total: number
    list: ProcessOrderDto[]
}

export interface PayrollRecordDto {
    companyId: number
    date: string
    idCardNo: string
    paymentAmount: number
    received: number
    stopPay: number
    totPay: number
    unclaimed: number
    userName: string
}

export interface GetResidentList {
    userName: string
    phone: string
    userId: number
}

interface getProcessOrderDto extends QueryPageDto, QueryDateSlotDto {
    createUserName?: string
    remarks: string
    state?:string | number | null | undefined
}
// 查询发薪流程单
export function getProcessOrder (
    data: getProcessOrderDto
): Promise<GetProcessOrderResDto> {
    return request.get('/rest/pay/processOrder/list', {
        params: data
    })
}
export interface NotGetPeopleDto {
    userName: string
    idCardNo: string
    serveCompanyName: string
    projectName: string
    phone: string
    paymentAmount: string
    payTime: string
    status: number
    statusMsg: string
}

// 查询未领薪人员名单
export function getNotGetPeople (
    params: QueryPageDto
): Promise<{
    totalCount: number
    list: NotGetPeopleDto[]
    payUnclaimedTotalMoney: number
    payUnclaimedCount: number
}> {
    return request.get('/rest/pay/pay-payment-record/list-unclaimed', {
        params
    })
}

export interface ProcessDetailDto {
    serveCompanyName: string
    projectName: string
    flowCode: string
    createTime: string
    totalMoney: number
    peopleNum: number
    remarks: string
    getSalaryCount: number
    state: number
    payStopTotalMoney:string
    payRealHairTotalMoney:string
    payStopCount:string
}
export function getProcessDetail (params: {
    id: string
}): Promise<ProcessDetailDto> {
    return request.get('/rest/pay/processOrder', {
        params
    })
}

export interface ProcessWorkersDto extends NotGetPeopleDto {
    getSalaryTime: string
}
interface getProcessWorkersDto extends QueryPageDto {
    processId: string,
    status?: any
}
export function getProcessWorkers (
    params: getProcessWorkersDto
): Promise<{ total: number; list: ProcessWorkersDto[] }> {
    return request.get('/rest/pay/pay-payment-record/list', {
        params
    })
}
interface getPostBatchPayDto {
    flowCode: string
    linkman: string[]
    projectName: string
    remarks: string
    serveCompanyId: string
    serveCompanyName: string
    totalMoney: number
    totalPerson: string
    userList: {
        id?: number | string | undefined
        idCard: string
        totalMoney: number | string
        userName: string
    }[]
}
// 批量发薪
export function postBatchPay (
    data: getPostBatchPayDto
): Promise<{ total: number; list: ProcessWorkersDto[] }> {
    return request.post('/rest/pay/processOrder', data)
}
interface getPayrollRecordDto extends QueryPageDto {
    beginPayTime: string
    endPayTime: string
    beginGetSalaryTime: string
    endGetSalaryTime: string
    serveCompanyId: string
    detailRemarks?: string
}
// 领薪记录
export function payrollRecord (
    data: getPayrollRecordDto
): Promise<{ list: PayrollRecordDto[]; total: number }> {
    return request.get('/rest/pay/pay-payment-record/list-group', {
        params: data
    })
}

// 停止发薪 /rest/pay/pay-payment-record/updateStatus/{ids}
export function stopPay (ids: string): Promise<GetProcessOrderResDto> {
    return request.put('/rest/pay/pay-payment-record/updateStatus/' + ids)
}

interface getPayrollRecordOwnDto extends QueryPageDto, QueryMonthDto {}
// 个人领薪记录 /rest/pay/pay-personal-paid/list
export function payrollRecordOwn (
    data: getPayrollRecordOwnDto
): Promise<{ list: PayrollRecordDto[]; total: number }> {
    return request.get('/rest/pay/pay-personal-paid/list', {
        params: data
    })
}

// 发薪筛选人 Screening people rest/pay/processOrder/submitter
export function screeningPeople (params: any = {}, config :any = {}): Promise<string[]> {
    return request.get('/rest/pay/processOrder/submitter', {
      params: params,
      headers: config
    })
}

// 未领薪筛选 /system/dict/data/type/
export function payScreenType (
    data: string,
    config: any = {}
): Promise<{ dictLabel: string; dictValue: string }[]> {
    return request.get('/system/dict/data/type/' + data, {
      params: data,
      headers: config
    })
}

// 个人全部月份领薪记录 /rest/pay/pay-personal-paid/worker-by-month?
export function payrollRecordOwnAll (data: {
    idCardNo: string
}): Promise<{ list: PayrollRecordDto[]; total: number }> {
    return request.get('/rest/pay/pay-personal-paid/worker-by-month?', {
        params: data
    })
}

// 获取审批流 表
export function getApprovalProcess (params:{id:number}): Promise<any> {
    return request.get('/rest/pay/processOrder/get-approval-info', {
        params: params
    })
}
// 同意或取消 审批流表
export function approval (
    data: {
        serveCompanyId:number,
        id:number,
        params:{
            isAgree:number
            auditContent:string
        }
    }
): Promise<void> {
    return request.post('/rest/pay/processOrder/audit-order', data)
}
