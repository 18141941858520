/*
    Description: 请求配置
    Use: api内其他文件
*/
import axios, { AxiosRequestConfig } from 'axios'
import { ElMessage, ElLoading, ILoadingInstance } from 'element-plus'
import global from '@/utils/global'
import user from '@/utils/user'
import store from '../store'

const arr = [
    '/pay-module-export/salaried-detailed-by-process-ids',
    '/pay-module-export/processOrder/export',
    '/pay-module-export/statistics-info-all',
    '/pay-module-export/pay-stub/export',
    '/pay-module-export/pay-payment-record',
    '/pay-module-export/pay-personal-paid',
    '/pay-module-export/archived-export',
    '/ele-module-export/ele-sign-contract-record',
    '/insure-module-export/export-by-condition',
    '/insure-module-export/insure-info',
    '/pay-module-export/recharge-record',
    '/insure-module-export/list-insure-effective-user-export',
    '/insure-module-export/list-wesure-effective-user-export',
    '/pay-module-export/create-personnel-receipt'
]
export const baseURL = process.env.VUE_APP_BASE_URL
let taskNum = 0
let loading: ILoadingInstance
// 初始化
const request = axios.create({
    baseURL
})
console.log(baseURL, 'baseURLbaseURL')
// 定义config数据类型
export interface RequestConfigDto extends AxiosRequestConfig {
    isLoading?: boolean
}

// 配置axios
request.interceptors.request.use(
    config => {
        // 仅匹配 arr数组里的 rul地址 这个是线上的
        if (config.baseURL === 'https://pro.51zidan.com/prod-api/') {
            arr.forEach((item:string) => {
                if (config.url?.split('?')[0] === item) {
                    config.baseURL = 'https://pro.51zidan.com/export-prod/'
                }
            })
        }
        // 仅匹配 arr数组里的 rul地址 这个是线上的
        if (config.baseURL === 'https://test.51zidan.com/testweb') {
          arr.forEach((item:string) => {
            if (config.url?.split('?')[0] === item) {
              config.baseURL = 'https://test.51zidan.com/export-test'
            }
          })
        }
        //  仅匹配 arr数组里的 rul地址 这个是本地的
        if (config.baseURL === process.env.VUE_APP_BASE_URL) {
            arr.forEach((item:string) => {
                if (config.url?.split('?')[0] === item) {
                    config.baseURL = process.env.VUE_APP_BASE_EXPORT_URL
                }
            })
        }
        config.headers = config.headers || {}
        if (global.token) {
            config.headers.Authorization = 'Bearer ' + global.token
        }
        if (!config.headers || !config.headers.loading) {
            taskNum += 1
            if (taskNum === 1) {
                loading = ElLoading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0)' })
            }
        }
        return config
    },
    error => {
        console.error('请求错误：', error.response)
        return Promise.reject(error.response)
    }
)

// 路由响应拦截
// http response 拦截器
request.interceptors.response.use(
    response => {
        if (response.config.headers && !response.config.headers.loading) {
            taskNum -= 1
            if (taskNum === 0) {
                loading.close()
            }
        }
        let data = {}
        switch (response.data.code) {
        case 401:
            loading.close()
            user.exit()
            return Promise.reject(response.data)
        case 404:
            loading.close()
            ElMessage.error('网络请求错误，请稍后重试或联系管理员！')
            return Promise.reject(response.data)
        case 200:
            if (response.data.data) {
                console.log('进入200')
                data = response.data.data
            } else {
                console.log('进入2001')
                data = response.data
            }
            return data
        default:
            if (response.headers['content-type'] === 'application/octet-stream') {
                let filename = response.headers['content-disposition']
                filename = filename.replace(/attachment;filename/, '')
                store.commit('setBlobFileName', decodeURIComponent(filename))
                // store.setFileName(decodeURIComponent(filename))
                return response.data
            } else {
                loading.close()
                const msg =
                response.data.msg ?? '网络请求错误，请稍后重试或联系管理员！'
                if (msg.includes('\r\n\r\n')) {
                    const msgList = msg.split('\r\n\r\n')
                    let html = ''
                    msgList.forEach((item: string, index: number) => {
                        if (index === msgList.length - 1) {
                            html += '<div>' + item + '</div>'
                        } else {
                            html += '<div style="margin-bottom: 20px">' + item + '</div>'
                        }
                    })
                    ElMessage({
                        message: html,
                        type: 'error',
                        dangerouslyUseHTMLString: true
                    })
                } else {
                    ElMessage.error(msg)
                }
                return Promise.reject(response.data)
            }
        }
    },
    error => {
        loading.close()
        const msg =
            error.response?.data?.message ?? '网络连接错误，请检查网络后重试！'
        if (msg.includes('\r\n\r\n')) {
            const msgList = msg.split('\r\n\r\n')
            let html = ''
            msgList.forEach((item: string) => {
                html += '<div style="margin-bottom: 10px">' + item + '</div>'
            })
            ElMessage({
                message: html,
                type: 'error',
                dangerouslyUseHTMLString: true
            })
        } else {
            ElMessage.error(msg)
        }
        return Promise.reject(error.response.data) // 返回接口返回的错误信息
    }
)
export default request
