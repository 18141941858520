
import { defineComponent } from 'vue'
import containerHeader from './components/container-header.vue'
import containerNav from './components/container-nav.vue'
import store from '@/store'
import user from '../src/utils/user'
export default defineComponent({
    name: 'Home',
    components: {
        containerHeader,
        containerNav
    },
    data() {
        return {
            isNav: false,
            theme: {
                _text: 'red'
            },
            $store: store
        }
    },
    async created() {
        await user.waitLogin()
        await store.commit('getLongPollingFile')
        console.log('获取环境', process.env.VUE_APP_BASE_URL)
    },
    methods: {
        onAdd() {
            store.commit('onModifyDownload')
        }
    }

    // created () {}
})
