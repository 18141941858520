
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus';
import { queryCollectData } from '@/api/advance.ts';
export default defineComponent({
    props: {
        dateValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            detail: {} as any,
            trendData: {
                payTotalMoney: {
                    name: '实发金额',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                payReceiveTotalMoney: {
                    name: '领薪金额',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                payAvailableTotalMoney: {
                    name: '待领金额',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                payStopTotalMoney: {
                    name: '停发金额',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                totalPracticalAdvanceAmount: {
                    name: '实发预支',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                totalSalaryAdvanceAmount: {
                    name: '预支已领',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                totalUnclaimedAdvanceAmount: {
                    name: '预支待领',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
                totalStopAdvanceAmount: {
                    name: '预支停发',
                    value: 0,
                    prefix: '¥',
                    subfix: ''
                },
            } as any
        }
    },
    watch: {
        dateValue: {
            handler(newval) {
                if (newval) {
                    this.getData()
                }
            },
            immediate: true
        }
    },
    methods: {
        getData() {
            queryCollectData({
                queryMonth: this.dateValue
            }).then((res: any) => {
                this.detail = res
                for(const key in this.trendData) {
                    this.trendData[key].value = res[key]
                }
            })
        }
    }
})
