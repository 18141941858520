import { stringObjectDTO } from './dto'
import { ElMessageBox, MessageBoxData } from 'element-plus'
/**
 * 时间格式化
 */
export function dateFormat(fmt: string, date: Date): string {
    let ret
    const opt: stringObjectDTO = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
            fmt = fmt.replace(
                ret[1],
                ret[1].length === 1
                    ? opt[k]
                    : opt[k].padStart(ret[1].length, '0')
            )
        }
    }
    return fmt
}

/**
 * 阻塞等待
 */
export function wait(timeout = 1000): Promise<undefined> {
    return new Promise(resolve => {
        setTimeout(resolve, timeout)
    })
}

/**
 * 节流
 */
function Shake() {
    let timer: any = -1
    return function (obj: () => undefined, millisecond = 300) {
        clearTimeout(timer)
        timer = setTimeout(obj, millisecond)
    }
}
export const shake = Shake()

// 确认窗
export function confirm(title: string, desc: string): Promise<MessageBoxData> {
    return ElMessageBox.confirm(desc, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
    })
}

// 输入窗
export function prompt(title: string, desc: string): Promise<MessageBoxData> {
    return ElMessageBox.prompt(desc, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
    })
}

export function closewin(): void {
    setTimeout(() => {
        if (
            navigator.userAgent.indexOf('Firefox') !== -1 ||
            navigator.userAgent.indexOf('Chrome') !== -1
        ) {
            window.location.href = 'about:blank'
            window.close()
        } else {
            window.opener = null
            window.open('', '_self')
            window.close()
        }
    }, 5000)
}
// 身份证转年龄
export function getCardAge(identityCard: string) {
    const len = (identityCard + '').length
    if (len === 0) {
        return 0
    } else {
        if ((len !== 15) && (len !== 18)) { // 身份证号码只能为15位或18位其它不合法
            return 0
        }
    }
    let strBirthday = ''
    if (len === 18) { // 处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday = identityCard.substr(6, 4) + '/' + identityCard.substr(10, 2) + '/' + identityCard.substr(12, 2)
    }
    if (len === 15) {
        strBirthday = '19' + identityCard.substr(6, 2) + '/' + identityCard.substr(8, 2) + '/' + identityCard.substr(10, 2)
    }
    console.log(strBirthday, 'strBirthday')
    // 时间字符串里，必须是“/”
    const birthDate = new Date(strBirthday)
    console.log(birthDate, 'birthDate')
    const nowDateTime = new Date()
    let age = nowDateTime.getFullYear() - birthDate.getFullYear()
    // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
    if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}
export interface FormTestingErroeDto {
    richText: {
        text: string
    },
}
export interface FormTestingDto {
    userName: string | FormTestingErroeDto[],
    idCardNo: string | FormTestingErroeDto[],
    serveCompanyName: null,
    joinType: number
}
// 表格校验
export function formTesting(name: string | FormTestingErroeDto[], idCode: string | FormTestingErroeDto[]) {
    console.log(name, '校验名称')
    console.log(idCode, 'code名称')
    let text = ''
    let code = ''
    if (typeof (name) === 'object' || typeof (idCode) === 'object') {
        if (typeof (name) === 'object' && typeof (idCode) === 'string') {
            (name as any).richText.forEach((item: { text: string }) => {
                text = text += item.text
            })
            code = (idCode as string)
        }
        if (typeof (idCode) === 'object' && typeof (name) === 'string') {
            (idCode as any).richText.forEach((item: { text: string }) => {
                code = code += item.text
            })
            text = (name as string)
        }
        if (typeof (idCode) === 'object' && typeof (name) === 'object') {
            (idCode as any).richText.forEach((item: { text: string }) => {
                code = code += item.text
            });
            (name as any).richText.forEach((item: { text: string }) => {
                text = text += item.text
            })
        }
        // console.log((item.getCell(1).value as any).richText, 'item.getCell(1).value')
        // (item.getCell(1).value as any).richText
        return {
            name: text,
            code: code
        }
    }
    return {
        name: '',
        code: ''
    }
}
export function urlTobase64(url: any) {
    return new Promise(resolve => {
        const file = url.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result)
        }
    })
}
// axios 请求 oss存储链接 转换成二进制后使用a标签下载
export function downLoadFile(res: any, type: number, time: any, remark: any) {
    console.log(res, 'resres')
    const typeArr = [
        '发薪单列表',
        '发薪明细',
        '对账单',
        '工资单',
        '领薪记录',
        '个人领薪记录',
        '归档管理',
        '合同列表',
        '批量投保明细',
        '日保列表',
        '充值记录',
        '下载中心',
        '长期保员工名单',
        '日保在保人员名单',
        '长期保在保人员名单',
        '人员维度子弹回单'
    ]
    const secondary = {
        pay: '发薪充值记录',
        insurance: '日日保充值记录',
        sign: '电子签充值记录',
        period: '长期保充值记录'

    } as any
    console.log(remark, 'remarkremarkremark')
    const headers = res.headers
    const contentType = headers['Content-Type']
    const contentDisposition = headers['Content-Disposition']
    const blob = new Blob([res.data], { type: contentType })
    console.log(blob, 'blobblob')
    console.log('contentDisposition', contentDisposition)
    // 下载后文件名
    // const fileName = contentDisposition ? decodeURI(res.headers['Content-Disposition'].split(';')[1].split('=')[1]) : new Date().getTime() + (res.request.responseURL.includes('.xlsx') ? '.xlsx' : '.zip')
    // res.headers['Content-Disposition'].split(';')[1].split('=')[1]) : new Date().getTime() + (res.request.responseURL.includes('.xlsx') ? '.xlsx' : '.zip'
    let file_name = '.' + res.request.responseURL.match(/\.([^\.]+)$/)[1]
    const fileName = contentDisposition ? decodeURI(res.headers['Content-Disposition'].split(';')[1].split('=')[1]) : (type - 1 === 10 ? secondary[`${remark}`] : typeArr[type - 1]) + '_' + dateFormat('YYYY-mm-dd HH:MM:SS', new Date(time)) + file_name
    console.log(type - 1, typeArr[type - 1])
    console.log(fileName, '文件名字')
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob) // 创建下载的链接
    downloadElement.href = href
    downloadElement.download = fileName
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(href) // 释放掉blob对象
}

export function isWeixin() {
    return navigator.userAgent.toLowerCase().indexOf('wechat') > -1
}

export function filterName(name: string) {
    if (name) {
        const len = name.length
        let others = ''
        for (let i = 0; i < len - 1; i++) {
            others += '*'
        }
        return name.charAt(0) + others
    }
    return ''
}

export function filterCompanyName(name: string) {
    if (name) {
        let len = name.length - 6
        if (len > 6) len = 6
        let others = ''
        for (let i = 0; i < len; i++) {
            others += '*'
        }
        return name.replace(/^(.{2}).+(.{4})$/, `$1${others}$2`)
    }
    return ''
}

export function filterProjectName(name: string) {
    if (name) {
        if (name.length > 3) {
            let len = name.length - 2
            if (len > 6) len = 6
            let others = ''
            for (let i = 0; i < len; i++) {
                others += '*'
            }
            return name.replace(/^(.{1}).+(.{1})$/, `$1${others}$2`)
        } else {
            return filterName(name)
        }
    }
    return ''
}

/**
 * 通过url下载文件并对下载的文件更名
 * @param {*} url
 * @param {*} filename
 */
export const downloadFileAndChangeName = (url: any, filename: any) => {
    function getBlob(url: string | URL) {
        return new Promise(resolve => {
            const XML = new XMLHttpRequest()
            XML.open('GET', url as any, true)
            XML.responseType = 'blob'
            XML.onload = () => {
                if (XML.status === 200) {
                    resolve(XML.response)
                }
            }
            XML.send()
        })
    }
    // 下载文件
    function saveAs(blob: any, filename: string) {
        const elelink = document.createElement('a')
        elelink.style.display = 'none'
        elelink.download = filename
        elelink.href = window.URL.createObjectURL(blob)
        document.body.appendChild(elelink)
        elelink.click()
        document.body.removeChild(elelink)
    }
    // 调用以上方法进行下载
    getBlob(url).then(blob => {
        saveAs(blob, filename)
    })
}

export default {
    prompt,
    confirm,
    filterName,
    filterCompanyName
}
