import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element, { ElIcon, ElMessageBox } from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/theme-chalk/index.css'
import user from '@/utils/user'
import global from '@/utils/global'
import './vue.dto'
import util from '@/utils/util'
import mitt from 'mitt' // 全局事件总线
import * as ElIcons from '@element-plus/icons';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import exportButton from '@/components/export-button.vue'

const Mit = mitt()

// 声明使用，因为没有TS的约束要用declare，才能使用。

declare module 'vue' {
  export interface ComponentCustomProperties {
    $Bus: typeof Mit
  }
}
const app = createApp(App)

for (const name in ElIcons) {
  app.component(name, (ElIcons as any)[name])
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// // 监听表格滚动
// app.directive('loadmore', {
//     beforeUpdate (el: any, binding: any) {
//         console.log(el, 'elelelel', binding, 'bindingbinding')
//         const selectWrap = el.querySelector('.el-table__body-wrapper')
//         selectWrap.addEventListener('scroll', function () {
//             const scrollDistance =
//         el.scrollHeight - el.scrollTop - el.clientHeight
//             if (scrollDistance <= 0.5) {
//                 binding.value()// 执行在使用时绑定的函数，在这里即loadMorePerson方法
//             }
//         })
//     }
// })
app.use(store)
    .use(router)
    .use(Element, { locale })
    .mount('#app')
if (sessionStorage.token) {
    global.token = sessionStorage.token
    user.getUserInfo()
}
app.component('export-button', exportButton)
app.config.globalProperties.$util = util
app.config.globalProperties.$Bus = Mit

app.config.globalProperties.$hideXiWei = false
