import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39d2de76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isCloseType ? '' : _ctx.close()))
  }, [
    _createElementVNode("div", {
      class: "container-c",
      style: _normalizeStyle({ width: _ctx.width }),
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.dataTitle), 1),
      _createElementVNode("div", {
        class: "close point",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }, "×"),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ]))
}