<template>
    <el-upload ref="uploadRef" action="" :accept="accept" v-model:file-list="fileList" :auto-upload="false" :limit="limit"
        :multiple="multiple" :on-change="handleChange" :list-type="listType" :on-remove="handleRemove"
        :on-exceed="handleExceed" :on-preview="handlePictureCardPreview">
        <div class="tips">添加付款凭证</div>
    </el-upload>
    <Teleport to="body">
        <div v-if="dialogVisible" class="shadow-box">
            <div class="close pointer" @click="dialogVisible = false">
                &times;
            </div>
            <div class="img-box">
                <el-image :src="dialogImageUrl" fit="cover" />
            </div>
        </div>
    </Teleport>
</template>
<script>
import { defineComponent } from 'vue'
import { ElMessage, ElMessageBox, genFileId } from 'element-plus'
import * as imageConversion from 'image-conversion'

export default defineComponent({
    name: 'UploadImage',
    props: {
        accept: {
            type: String,
            default: '.jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF'
        },
        base64File: {
            type: [String, Array]
        },
        fileUrl: {
            type: [String, Array]
        },
        limit: {
            type: [Number, String],
            default: 1
        },
        multiple: {
            type: Boolean,
            default: false
        },
        listType: {
            type: String,
            default: 'picture-card'
        },
        limitSize: {
            type: Number,
            limit: 2
        },
        exceed: {
            type: Boolean,
            default: false
        },
        canCompress: {
            type: Boolean,
            default: false
        },
        propName: {
            type: String,
            default: ''
        },
        removeConfirm: {
            type: Boolean,
            default: false
        }
    },
    components: {

    },
    data() {
        return {
            dialogVisible: false,
            dialogImageUrl: '',
            fileList: []
        }
    },

    methods: {
        async handleChange(file) {
            console.log(555555, file)
            let rawFile = file.raw
            if (!rawFile) return
            const typeList = this.accept.split(',')
            const fileType = rawFile.name.substring(rawFile.name.lastIndexOf('.'))
            if (typeList.every(i => i !== fileType)) {
                ElMessage.error('只能上传格式为图片的文件')
                fileList.value = []
                return false
            } else if (rawFile.size / 1024 / 1024 > this.limitSize) {
                if (this.canCompress) {
                    rawFile = await imageConversion.compressAccurately(rawFile, (this.limitSize - 1) * 1024)
                } else {
                    ElMessage.error(`只能上传大小为${this.limitSize}M以内的图片`)
                    fileList.value = []
                    return false
                }
            }
            const imgFile = new FileReader()
            imgFile.readAsDataURL(rawFile)
            imgFile.onload = res => {
                let base64 = res.target.result.split('base64,')[1]
                rawFile.base64 = base64
                if (Array.isArray(this.fileUrl)) {
                    // emits('update:base64File', [...props.base64File, base64])
                    this.$emit('success', base64)
                } else {
                    this.$emit('update:base64File', base64)
                }
            }
            return true
        },
        handleRemove(file) {
            console.log(2121, file)
            // const list = fileList.value.map(i => {
            //     return i.raw.base64
            // })
            if (Array.isArray(this.fileUrl)) {
                // emits('update:base64File', list)
                this.$emit('remove', file)
            } else {
                this.$emit('update:base64File', '')
            }
        },
        handleExceed(files) {
            if (this.exceed) {
                this.$refs.uploadRef.clearFiles()
                const file = files[0]
                file.uid = genFileId()
                this.$refs.uploadRef.handleStart(file)
            } else {
                ElMessage.error({
                    message: `最多只能上传${this.limit}张`
                })
            }
        },
        handlePictureCardPreview(uploadFile) {
            console.log(2222, uploadFile)
            this.dialogImageUrl = uploadFile.url
            this.dialogVisible = true
        },

        getBase64Image(img) {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            const dataURL = canvas.toDataURL()
            return dataURL
        },
        createFile(url) {
            const image = new Image()
            let newUrl = url
            if (this.propName) {
                newUrl = url[this.propName]
            }
            image.src = newUrl + '?v=' + Math.random()
            image.crossOrigin = 'anonymous'
            image.onload = () => {
                const fullbase64 = this.getBase64Image(image)
                let base64 = fullbase64.split('base64,')[1]
                if (Array.isArray(this.base64File)) {
                    // emits('update:base64File', [{
                    //     ...url,
                    //     base64
                    // }])
                } else {
                    this.$emit('update:base64File', base64)
                }
            }
            if (typeof url === 'string') {
                this.fileList.push({
                    url: newUrl
                    // uid: genFileId()
                })
            } else {
                this.fileList.push({
                    ...url,
                    url: newUrl
                    // uid: genFileId()
                })
            }
        }
    }
})
</script>

<style lang="stylus" scoped>
.tips
    position relative
    &::before
        content '+'
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -75%) scale(3)
:deep(.el-upload-list--picture-card .el-upload-list__item)
    height 100px
.shadow-box
    position fixed
    left 0
    top 0
    width 100vw
    height 100vh
    background rgba(0, 0, 0, 0.5)
    z-index 10086

.close
    position absolute
    right 20px
    top 20px
    font-size 40px
    color #fff

.img-box
    position absolute
    left 50%
    top 50%
    transform translate(-50%, -50%)
    width auto
    max-height 70vw
.parent
    position relative
</style>
