import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f400250"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tagsPages"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$store.state.tagsPages.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.tagsPages, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['pages','point',_ctx.$route.name === item.name ? 'active' : '']),
            key: index,
            onClick: _withModifiers(($event: any) => (_ctx.goTabPage(item)), ["stop"])
          }, [
            _createTextVNode(_toDisplayString(item.title) + " ", 1),
            _createElementVNode("i", {
              class: "el-icon el-icon-circle-close",
              onClick: _withModifiers(($event: any) => (_ctx.delTab(item.path,item.name)), ["stop"])
            }, null, 8, _hoisted_3)
          ], 10, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}