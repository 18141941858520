import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_containerNav = _resolveComponent("containerNav")!
  const _component_container_header = _resolveComponent("container-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app-body",
    style: _normalizeStyle(_ctx.$store.state.isNav ? '' : 'width: 100vw'),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAdd && _ctx.onAdd(...args)))
  }, [
    (_ctx.$store.state.isNav)
      ? (_openBlock(), _createBlock(_component_containerNav, {
          key: 0,
          style: {"flex-shrink":"1"}
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_el_scrollbar, {
      class: "container-box",
      style: _normalizeStyle(_ctx.$store.state.isNav ? '' : 'min-width: 100vw;padding: 0')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.$store.state.isNav)
            ? (_openBlock(), _createBlock(_component_container_header, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_KeepAlive, {
                include: _ctx.$store.state.tagsPages.map(item => item.name)
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ], 1032, ["include"]))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["style"])
  ], 4))
}