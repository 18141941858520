
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus';
import {
    getPayTrend,
} from '@/api/home'
import { queryAdvanceTrend } from '@/api/advance.ts';
import trendData from './trend-data.vue';
import trendChart from './trend-chart.vue';
import dayjs from 'dayjs'
const curMonth = dayjs().format('YYYY-MM')

export default defineComponent({
    components: {
        trendData,
        trendChart
    },
    data() {
        return {
            activeName: 'pay',
            dateValue: curMonth,
            payOption: {},
            advanceOption: {}
        }
    },
    mounted() {
    },
    beforeMount() {
        this.getData()
        this.getAdvanceData()
    },
    methods: {
        toggle(name: string) {
            this.activeName = name
            this.$nextTick(() => {
                if (this.activeName === 'pay') {
                    (this as any).getData()
                    (this as any).$refs.pay.init(this.payOption)
                } else if (this.activeName === 'advance') {
                    (this as any).getAdvanceData()
                    (this as any).$refs.advance.init(this.advanceOption)
                }
            })
        },
        getData() {
            getPayTrend({
                queryMonth: this.dateValue
            }).then(res => {
                if (res?.list?.length) {
                    this.payOption = {
                        title: {
                            text: ''
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['发薪', '领薪'],
                            left: 'left',
                            top: '15px'
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: res.list.map(i => i.date)
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: '发薪',
                                type: 'line',
                                smooth: true,
                                data: res.list.map(i => i.payTotalMoney)
                            },
                            {
                                name: '领薪',
                                type: 'line',
                                smooth: true,
                                data: res.list.map(i => i.payReceiveTotalMoney)
                            },
                        ]
                    }
                }
            })
        },
        getAdvanceData() {
            queryAdvanceTrend({
                queryMonth: this.dateValue
            }).then((res: any) => {
                console.log(66666, res);
                this.advanceOption = {
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['领薪', '预支'],
                        left: 'left',
                        top: '15px'
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: res.date
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '领薪',
                            type: 'line',
                            smooth: true,
                            data: res.totalSalaryMoney
                        },
                        {
                            name: '预支',
                            type: 'line',
                            smooth: true,
                            data: res.totalAdvanceMoney
                        },
                    ]
                }
            })
        },
        handleDateChange() {
            if (this.activeName === 'pay') {
                this.getData()
            } else if (this.activeName === 'advance') {
                this.getAdvanceData()
            }
        }
    }
})
